/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { Container, Col } from 'reactstrap';

import Header from './header';
import './layout.css';
import './styles.css';
import './tree.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <div className="site">
      <Container>
        <Header siteTitle={data.site.siteMetadata.title} />
        <Col className="main-content d-flex align-items-center flex-column">
          <main className="mb-auto">{children}</main>
          <footer>
            © {new Date().getFullYear()},{' '}
            <span className="highlight">EIGHTSH ONE</span>
          </footer>
        </Col>
      </Container>
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
